import React, { Component } from 'react';
import {
    Typography,
} from '@material-ui/core';
import './Card.scss';

const DEFAULT_FLIP_DELAY = 300;

class PlayingCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flipClass: this.props.disableFlip ? "" : " flip-card"
        };
    }

    componentDidMount() {
        const flipDelay = this.props.flipDelay ? this.props.flipDelay : DEFAULT_FLIP_DELAY;
        this.updateTimer = setTimeout(() => {
            this.setState({ flipClass: "" });
            this.updateTimer = null;
        }, flipDelay);
    }
	
    componentWillUnmount() {
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
        }
    }

    updateAndNotify = () => {
        if (this.updateTimer || this.props.disableFlip) return;

        const flipDelay = this.props.flipDelay ? this.props.flipDelay : DEFAULT_FLIP_DELAY;
        this.setState({ flipClass: " flip-card" });
        this.updateTimer = setTimeout(() => {
            this.setState({ flipClass: "" });
            this.updateTimer = null;
        }, flipDelay);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.card.description !== this.props.card.description) {
            this.updateAndNotify();
        }
    }

    render() {
        let cardClasses = 'card-container';
        if (!this.props.perk) {
            cardClasses += ' red-flag';
        }
        if (this.props.selectedCards?.includes(this.props.card.index)) {
            cardClasses += ' selected';
        }

        const cardText = this.state.flipClass === "" ?
            this.props.card.description :
            "...";

        return (
            <div className={cardClasses + this.state.flipClass} onClick={this.handleClick}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <Typography variant="h4" className="card-description">
                            {cardText}
                        </Typography>
                    </div>
                    <div className="flip-card-back">
                        <img className="flag-logo" src="/assets/flag-logo.png" alt="logo"></img>
                    </div>
                </div>
            </div>
        )
    };

    handleClick = (event) => {
        if (this.props.disableClick) {
            return;
        }
        this.props.onSelect(this.props.card.index);
    };
}

export default PlayingCardComponent