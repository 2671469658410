import React, { Component } from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import './Scoreboard.scss';


class ScoreboardComponent extends Component {
    render() {
        const sortedPlayers = [...this.props.game.players].sort((a,b) => b.points - a.points);
        const youAreSub = this.props.game.players[this.props.game.youAre].sub;

        return (<div className="section">
            <Grid container justify="center">
                <Typography variant="h3" className="scoreboard-title">
                    Scoreboard
                </Typography>
                {sortedPlayers.map((p) => {
                    return this.playerDetails(p, youAreSub)
                })}
            </Grid>
        </div>)
    }

    /**
     * Render player details
     * @param {Player} p 
     * @param {Number} youAreSub - sub of current Player
     */
    playerDetails(p, youAreSub) {
        const name = p.sub === youAreSub ?
            (
                <b>{p.name}</b>
            ) :
            p.name;

        return (
            <Grid container alignItems="center" justify="space-between" key={p.name} className="score-container">
                <Typography variant="h5">
                    {name}
                </Typography>
                <Typography variant="h5">
                    {p.points}
                </Typography>
            </Grid>
        )
    }
}
export default ScoreboardComponent