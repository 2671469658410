import React, { Component } from 'react';
import './App.scss';
import { withRouter, useParams } from 'react-router-dom';
import service from './GameService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Typography, Icon, Grow, Input } from '@material-ui/core';
import Players from './Players';
import Judge from './Judge';
import FullscreenButton from './Fullscreen';
import PlayingCard from './Card';
import Scoreboard from './Scoreboard';
import './Game.scss';
import WinnerDialog from './WinnerDialog';

const POLL_PERIOD = 2000 // milliseconds
const PHASE_INIT = "INIT"
const PHASE_JUDGE = "JUDGE"
const PHASE_PLAY = "PLAY"
const PHASE_OVER = "OVER"
const PHASE_REVIEW = "REVIEW"
const NUM_PLAYER_COLORS = 12;


class GameComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            game: undefined,
            metadata: undefined,
            gameId: props.gameId,
            found: true,
            errorMessage: undefined,
            mySub: undefined,
            intervalId: undefined,
            diceToKeep: [],
            items: {},
            awaitUpdate: false,
            flags: [],
            perks: [],
            selectedPerks: [],
            selectedFlags: [],
            isGuideComplete: true,
            gameSettings: {},
            showFireworks: true,
        }
        this.poll = this.poll.bind(this);
        this.handleJoinGame = this.handleJoinGame.bind(this)
        this.handleStartGame = this.handleStartGame.bind(this)
        this.handleAddBot = this.handleAddBot.bind(this)
        this.updateGameState = this.updateGameState.bind(this)
        this.handleOnReady = this.handleOnReady.bind(this)
        this.awaitUpdate = this.awaitUpdate.bind(this)
        this.handleOnJudge = this.handleOnJudge.bind(this)
        this.handleFinalizePerks = this.handleFinalizePerks.bind(this)
    }

    async componentDidMount() {
        try {
            const [game, flags, perks] = [...await Promise.all([
                service.getGame(this.state.gameId),
                service.flags(),
                service.perks()
            ])]

            if (game.message !== undefined) {
                this.setState({
                    errorMessage: game.message
                })
            }
            else {
                const playerColorMap = game.players.map(assignColors);
                const totalScore = game.players.map(p => p.points).reduce((a,b) => a + b);
                const newState = {
                    game,
                    flags,
                    perks,
                    found: true,
                    errorMessage: undefined,
                    playerColorMap,
                    isGuideComplete: totalScore > 0,
                    gameSettings: {
                        points: game.points
                    },
                }

                this.setState(newState)
                if (!game.over) {
                    let intervalId = setInterval(this.poll, POLL_PERIOD)
                    this.setState({ intervalId })
                }
            }
        }
        catch (e) {
            console.log(e)
            this.setState({ found: false })
        }
    }

    componentWillUnmount() {
        this.clearPolling()
    }

    render() {
        if (!this.state.found || this.state.gameId === undefined) {
            return (<p>{this.state.gameId} Not Found!</p>)
        }
        else if (this.state.errorMessage !== undefined) {
            return (<p>{this.state.errorMessage} Not Found!</p>)
        }
        else if (this.state.game === undefined) {
            return (
                <div className="loading-screen">
                    <CircularProgress size="80px" />
                </div>
            )
        }

        if (this.state.game.phase === PHASE_INIT) {
            return (
                <div className="game-container game-not-started">
                    <Grid container alignItems="center" justify="space-evenly" direction="column" style={{'minHeight': '80%'}}>
                        {this.join()}
                        {this.start()}
                        <Grid container alignItems="flex-start" justify="space-evenly">
                            {this.updateSettings()}
                            <Players game={this.state.game} playerColorMap={this.state.playerColorMap}/>
                        </Grid>
                        {this.addBot()}
                    </Grid>
                    <FullscreenButton white></FullscreenButton>
                </div>
            )
        }

        if (!this.state.isGuideComplete) {
            const transitionTimeout = {
                appear: 0,
                enter: 2000
            };

            return (
                <div className="game-container game-not-started">
                    <Grid container alignItems="center" justify="space-evenly" direction="column" className="guide-container">
                        <Typography variant="h2" className="guide-title">
                            How To Play
                        </Typography>
                        <Grow in={true} timeout={transitionTimeout}>
                            <Typography variant="h5">
                                <img src="/assets/flag-logo.png" alt="logo"></img>
                                <b>Setup: </b>
                                We each take turns being Single looking for a blind date, and everyone else just happens to know a <b><u>friend</u></b> who's just the perfect match
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout}  style={{transitionDelay: 2000}}>
                            <Typography variant="h5">
                                <img src="/assets/flag-logo.png" alt="logo"></img>
                                <b>The Perks: </b>
                                Pick two Perks to best describe this <b><u>friend</u></b> you know
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 4000}}>
                            <Typography variant="h5">
                                <img src="/assets/flag-logo.png" alt="logo"></img>
                                <b>The Red Flag: </b>
                                Turns out, you know a dark secret about someone else's <b><u>friend</u></b>
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 6000}}>
                            <Typography variant="h5">
                                <img src="/assets/flag-logo.png" alt="logo"></img>
                                <b>Debate: </b>
                                Once all Perks and Red Flags have been picked, each player will argue why the Single should pick their <b><u>friend</u></b> and reject the other candidates
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 8000}}>
                            <Typography variant="h5">
                                <img src="/assets/flag-logo.png" alt="logo"></img>
                                <b>Selection: </b>
                                Based on the arguments, the Single chooses a date
                            </Typography>
                        </Grow>
        
                        <Button variant="contained"
                                className="skip-guide-btn"
                                onClick={() => {this.setState({isGuideComplete: true})}}>
                            Got it, let's go!
                        </Button>
                    </Grid>
                    <FullscreenButton white></FullscreenButton>
                </div>
            );
        }

        return (
            <div className="game-container">
                {this.handleJudgementPhase()}
                {this.handlePlayPhase()}
                {this.handleScoreboardPhase()}
                <FullscreenButton></FullscreenButton>
            </div>
        )
    }
    
    handleJudgementPhase = () => {
        if (this.state.game.phase !== PHASE_JUDGE) {
            return (null);
        }
        const players = this.state.game.players;
        const perks = this.state.perks;
        const flags = this.state.flags;
        let blindDates = [];

        players.forEach((player, index) => {
            if (player.target === index) {
                return;
            }

            const blindDate = {
                player: player,
                playerIndex: index,
                perks: player.playedPerks.map(perkIndex => perks[perkIndex])
            };

            players.forEach((opponent, opponentIndex) => {
                if (index === opponent.target) {
                    blindDate.flag = flags[opponent.playedFlag];
                }
            });

            blindDates.push(blindDate);
        });

        return (
            <Judge
                game={this.state.game}
                dates={blindDates}
                onJudge={this.handleOnJudge}
            />
        );
    }

    handleScoreboardPhase = () => {
        if (this.state.game.phase !== PHASE_OVER && this.state.game.phase !== PHASE_REVIEW) {
            return (null)
        }

        // If game is over, show who won
        if (this.state.game.phase === PHASE_OVER) {
            return (
                <Grid container alignItems="center" justify="space-evenly" direction="column" className="game-over-container">
                    <Scoreboard game={this.state.game}/>
                    <Grid container justify="center" alignItems="center" direction="column" className="winner-section">
                        <Typography variant="h4">
                            <b>Game Over</b>
                        </Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>
                            Congratulations to the best matchmaker, <b>
                                {[...this.state.game.players].sort((a,b) => b.points - a.points)[0].name}
                            </b>!
                        </Typography>
                    </Grid>
                    <div className="fireworks">
                        {/* 
                            Executing raw jQuery based on current state is non-trivial in React.
                            I tried a few different approaches, but ultimately hacked my way through by
                            injecting javascript via onError of a 'bad' image.

                            We need to only call .fireworks() once, else the browser suffers serious
                            performance issues.
                        */}
                        <img src='x' alt='' onError={() => {
                            if (this.state.showFireworks) {
                                window.$('.fireworks').fireworks({ count: 5 });
                                this.setState({showFireworks: false});
                            }
                        }}></img>
                    </div>
                    <WinnerDialog
                        game={this.state.game}
                        flags={this.state.flags}
                        perks={this.state.perks}>
                    </WinnerDialog>
                </Grid>
            )
        }

        // If you are ready, show which players are not
        // else, show the previous winner and the scoreboard
        if (this.state.game.players[this.state.game.youAre].ready) {
            const statusIcon = (player) => {
                if (player.ready) {
                    return <Icon className="ready-check">check_circle</Icon>
                }

                return <CircularProgress size="40px" className="progress-status"/>
            };

            const status = this.state.game.players.map((player, playerIndex) => (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    key={`ready-${playerIndex}`}
                    className="player-status"
                >
                    {statusIcon(player)}
                    <Typography variant="h4">
                        {player.name}
                    </Typography>
                </Grid>
            ));

            return (
                <Grid container alignItems="center" justify="center" direction="column" className="play-container">
                    <Typography variant="h4" className="player-title">
                        Please hold tight while your friends get ready
                    </Typography>
                    {status}
                </Grid>
            )
        } else {
            return (
                <Grid container alignItems="center" justify="space-evenly" direction="column">
                    <Scoreboard game={this.state.game}/>
                    <Button variant="contained"
                            className="next-round-btn"
                            disabled={this.state.awaitUpdate}
                            onClick={() => {this.handleOnReady()}}>
                        Start Next Round
                    </Button>
                    <WinnerDialog
                        game={this.state.game}
                        flags={this.state.flags}
                        perks={this.state.perks}>
                    </WinnerDialog>
                </Grid>
            )
        }
    }
    
    handlePlayPhase = () => {
        if (this.state.game.phase !== PHASE_PLAY) {
            return (null);
        }
        
        const players = this.state.game.players;
        const myself = players[this.state.game.youAre];
        const currentJudge = players[this.state.game.currentPlayer].name;
        const perkCards = [];
        const flagCards = [];
        const isPerkPhase = myself.playedPerks.length === 0;

        // Check if you're the judge
        if (this.state.game.currentPlayer === this.state.game.youAre || myself.playedFlag > 0) {
            const competitors = players.filter((player, playerIndex) => {
                return playerIndex !== this.state.game.currentPlayer
            });

            const statusIcon = (player) => {
                if (player.playedFlag > 0) {
                    return (
                        <Icon className="ready-check">check_circle</Icon>
                    )
                } else {
                    return (
                        <CircularProgress size="40px" className="progress-status"/>
                    )
                }
            };

            const status = competitors.map(player => (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    key={`ready-${player.name}`}
                    className="player-status"
                >
                    {statusIcon(player)}
                    <Typography variant="h4">
                        {player.name}
                    </Typography>
                </Grid>
            ));

            return (
                <Grid container direction="column" alignItems="center" justify="center" className="play-container">
                    <Typography variant="h4" className="player-title">
                        Please hold tight while your friends pick out a few dates!
                    </Typography>
                    {status}
                </Grid>
            )
        }

        if (isPerkPhase) {
            myself.perks.forEach(perk => {
                perkCards.push({
                    index: perk,
                    key: `perk-${perk}`,
                    description:this.state.perks[perk] 
                });
            });
        }
        else {
            players[myself.target].playedPerks.forEach(perk => {
                perkCards.push({
                    index: perk,
                    key: `perk-${perk}`,
                    description:this.state.perks[perk] 
                });
            });

            // Check if your target has finished selecting their cards
            if (perkCards.length === 0) {
                return (
                    <Grid container direction="column" alignItems="center" justify="center" className="play-container">
                        <Typography variant="h4" className="player-title">
                            Please hold tight while <b>{players[myself.target].name}</b> finishes picking their perks
                        </Typography>
                    </Grid>
                )
            }

            myself.flags.forEach(flag => {
                flagCards.push({
                    index: flag,
                    key: `flag-${flag}`,
                    description:this.state.flags[flag] 
                });
            });
        }

        const finalizeButton = isPerkPhase ?
            (
                <Button variant="contained"
                        color="primary"
                        className="select-perks-btn"
                        disabled={this.state.awaitUpdate || this.state.selectedPerks.length !== 2}
                        onClick={() => {this.handleFinalizePerks()}}>
                    Select Perks
                </Button>
            ) : (
                <Button variant="contained"
                        color="primary"
                        className="select-flags-btn"
                        disabled={this.state.awaitUpdate || this.state.selectedFlags.length !== 1}
                        onClick={() => {this.handleFinalizeFlags()}}>
                    Select Flag
                </Button>
            );

        const instructions = isPerkPhase ?
                'Pick Two Perks' :
                (
                    <span>Pick a Red Flag for <b>{players[myself.target].name}</b>'s friend</span>
                )

        return (
            <Grid container direction="column" alignItems="center" justify="center" className="play-container">
                <Typography variant="h4" className="player-title">
                    <b>{currentJudge}</b> is looking for the perfect date
                </Typography>
                <Typography variant="h4" className="instructions">
                    {instructions}
                </Typography>
                <Grid container justify="center" alignItems="center" direction="column" className="cards-container">
                    <Grid container justify="space-evenly" className="perk-container">
                        {perkCards.map(card => {
                            return (
                                <PlayingCard
                                    key={card.key}
                                    card={card}
                                    perk
                                    onSelect={this.handlePerkSelected}
                                    selectedCards={this.state.selectedPerks}
                                    disableClick={!isPerkPhase} />
                            )
                        })}
                    </Grid>
                    <Grid container justify="space-evenly">
                        {flagCards.map(card => {
                            return (
                                <PlayingCard
                                    key={card.key}
                                    card={card}
                                    onSelect={this.handleFlagSelected}
                                    selectedCards={this.state.selectedFlags} />
                            )
                        })}
                    </Grid>
                </Grid>
                {finalizeButton}
            </Grid>
        );
    }

    handlePerkSelected = (perk) => {
        if (this.state.selectedPerks.includes(perk)) {
            this.setState({
                selectedPerks: this.state.selectedPerks.filter(selectedPerk => selectedPerk !== perk)
            });
        } else {
            this.setState({
                selectedPerks: this.state.selectedPerks.concat(perk)
            });
        }
    }

    handleFlagSelected = (flag) => {
        this.setState({
            selectedFlags: [flag]
        });
    }

    join() {
        if (this.state.game.canJoin) {
            return (
                <Button variant="contained"
                        className="join-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleJoinGame()}}>
                    Join
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    start() {
        if (this.state.game.canStart) {
            return (
                <Button variant="contained"
                        className="start-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleStartGame()}}>
                    Start
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    updateSettings() {
        if (!this.state.game.canSetPoints) {
            return (
                <div className="section" style={{width: '50%'}}>
                    <Grid container alignItems="center" justify="center" direction="column">
                        <Typography variant="h4" className="settings-title">
                            Settings
                        </Typography>
                        <Typography variant="h5">
                            <b>Game Id: </b>{this.state.game.uuid}
                        </Typography>
                        <Typography variant="h5">
                            <b>Points To Win: </b>{this.state.game.points}
                        </Typography>
                    </Grid>
                </div>
            );
        }

        const handleInputChange = (event) => {
            this.setState({
                gameSettings: {
                    points: Number(event.target.value)
                }
            });
        };

        return (
            <div className="section" style={{width: '50%'}}>
                <Grid container alignItems="center" justify="center" direction="column">
                    <Typography variant="h4" className="settings-title">
                        Settings
                    </Typography>
                    <Typography variant="h5">
                        <b>Game Id: </b>{this.state.game.uuid}
                    </Typography>
                    <Typography variant="h5">
                        <b>Points To Win:</b>
                        <Input
                            className="points-input"
                            value={this.state.gameSettings.points}
                            margin="dense"
                            onChange={handleInputChange}
                            inputProps={{
                                step: 1,
                                min: 2,
                                max: 20,
                                type: 'number',
                            }}
                        />
                    </Typography>
                    <Button variant="contained"
                            className="update-settings-btn"
                            disabled={this.state.awaitUpdate}
                            onClick={() => {this.handleUpdateGameSettings()}}>
                        Update Settings
                    </Button>
                </Grid>
            </div>
        );
    }

    addBot() {
        if (this.state.game.phase === PHASE_INIT) {
            return (
                <Button variant="contained"
                        className="add-bot-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleAddBot()}}>
                    Add Bot
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    async handleJoinGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.joinGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleStartGame() {
        try {
            this.setState({ awaitUpdate: true })
            const resp = await service.startGame(this.state.gameId)
            this.updateGameState(resp, true)
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleUpdateGameSettings() {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.updateGameSettings(this.state.gameId, this.state.gameSettings.points);
            this.updateGameState(resp, true);
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleAddBot() {
        try {
            this.setState({ awaitUpdate: true })
            const resp = await service.addBot(this.state.gameId)
            this.updateGameState(resp, true)
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleOnJudge(winner) {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.judge(this.state.gameId, winner);
            this.updateGameState(resp, true);
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleFinalizePerks() {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.playPerks(this.state.gameId, this.state.selectedPerks);
            this.updateGameState(resp, true);
            this.setState({
                selectedPerks: []
            });
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleFinalizeFlags() {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.playFlag(this.state.gameId, this.state.selectedFlags[0]);
            this.updateGameState(resp, true);
            this.setState({
                selectedFlags: []
            });
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleOnReady() {
        try {
            this.awaitUpdate(async () => {
                return await service.ready(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    /**
     * Poll for updates to the game
     */
    async poll() {
        if (!this.state.awaitUpdate) {
            try {
                const resp = await service.getGame(this.state.gameId)
                this.updateGameState(resp)
            }
            catch (e) {
                console.log(`${new Date().toISOString()}: ${e.message}`)
            }
        }
    }
    /**
     * Change game state as necessary
     * @param {Game} newGame
     * @param {boolean} gameStart - optional. if true, do dice init if appropiate
     */
    updateGameState(newGame, gameStart) {
        const newState = {
            game: newGame,
            awaitUpdate: false,
        }
        /*j
        // in a game hasn't started, but player array is greater than the color map
        if(newGameFull.game.players.length > this.state.playerColorMap.length) {
            newState.playerColorMap = newGameFull.game.players.map(assignColors)
        }

        if(hasUidChanged && this.isMyTurn(newGameFull)) {
            newState.diceToKeep = reinitDice(newGameFull.game.currentPlayer)
        }
        */
        this.setState(newState)
        if (newGame.over) {
            this.clearPolling()
        }
    }
    /**
     * Callback should return a game object. Sets the awaitUpdate flag to true before 
     * service returns, then false when data is returned
     * @param {async Function<GameFull>} callback 
     */
    async awaitUpdate(callback) {
        this.setState({ awaitUpdate: true })
        this.updateGameState(await callback())
    }
    /**
     * Stops the interval call, as necessary. This should only happen if the game is ended
     */
    clearPolling() {
        if (this.state.intervalId !== undefined) {
            clearInterval(this.state.intervalId)
            this.setState({ intervalId: undefined })
        }
    }
}

// function reinitDice(currentPlayer) {
//     let diceToKeep = []
//     for(let i = 0; i < currentPlayer.roll.length; i++) {
//         diceToKeep.push(true)
//     }
//     return diceToKeep
// }

function assignColors(p, pin) {
    return `playerColor${(pin % NUM_PLAYER_COLORS)}`
}

export { GameComponent }

export default function Game() {
    let { gameId } = useParams()
    const GameComponentWithRouter = withRouter(GameComponent)
    return (<GameComponentWithRouter gameId={gameId} />)
}
