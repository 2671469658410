import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Icon
} from '@material-ui/core';
import './Players.scss';


class PlayersComponent extends Component {
    render() {
        return (<div className="section" style={{width: '50%'}}>
            <Grid container justify="center">
                <Typography variant="h4" className="player-title">
                    Players
                </Typography>
                {this.props.game.players.map((p,pin) => {
                    return this.playerDetails(p, pin)
                })}
            </Grid>
        </div>)
    }

    /**
     * Render player details
     * @param {Player} p 
     * @param {Number} pin - player index
     */
    playerDetails(p, pin) {
        const name = pin === this.props.game.youAre ?
            (
                <b>{p.name}</b>
            ) :
            p.name;

        return (
            <Grid container alignItems="center" justify="center" key={p.name}>
                <Icon className="heart-icon">
                    favorite
                </Icon>
                <Typography variant="h5">
                    {name}
                </Typography>
            </Grid>
        )
    }
}
export default PlayersComponent