import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Icon,
    IconButton,
    Button
} from '@material-ui/core';
import './Judge.scss';
import PlayingCard from './Card';

const JUDGE_PHRASES = [
    "Pick me",
    "This one'll do",
    "Yaasss queen!!"
];


class JudgeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlindDate: 0,
            viewedBlindDates: [],
            awaitUpdate: false,
        };
        this.randomJudgePhrase = JUDGE_PHRASES[Math.floor(Math.random() * JUDGE_PHRASES.length)];
    }

    render() {
        if (this.props.dates === []) {
            return (<div></div>);
        }

        const blindDates = this.props.dates;
        const currentBlindDate = this.state.currentBlindDate;
        const isJudge = this.props.game.players[this.props.game.youAre].target === this.props.game.youAre;
        const buttonText = isJudge ? this.randomJudgePhrase : 'Judging...';
        const disableFlip = this.state.viewedBlindDates.includes(currentBlindDate);

        return (
            <Grid container justify="space-evenly" className="judge-container">
                <IconButton onClick={this.handleBack} className="back-btn" disabled={currentBlindDate === 0}>
                    <Icon>forward</Icon>
                </IconButton>
                <Grid container alignItems="center" justify="center" direction="column" className="center-container">                   
                    <Typography variant="h4" className="player-title">
                        {blindDates[currentBlindDate].player.name}'s friend...
                    </Typography>
                    
                    <Grid container justify="space-evenly" className="judge-card-container">
                        <PlayingCard 
                            key={'perk-0'}
                            card={{description: blindDates[currentBlindDate].perks[0]}} 
                            perk
                            flipDelay={500}
                            disableFlip={disableFlip}
                            disableClick={true} />
                        <PlayingCard 
                            key={'perk-1'}
                            card={{description: blindDates[currentBlindDate].perks[1]}} 
                            perk
                            flipDelay={700}
                            disableFlip={disableFlip}
                            disableClick={true} />
                        <PlayingCard 
                            key={'flag-0'}
                            card={{description: blindDates[currentBlindDate].flag}}
                            flipDelay={900}
                            disableFlip={disableFlip}
                            disableClick={true} />
                    </Grid>

                    <Button variant="contained"
                            color="primary"
                            className="judge-btn"
                            disabled={this.state.awaitUpdate || !isJudge}
                            onClick={() => {this.handleJudgement()}}>
                        {buttonText}
                    </Button>
                </Grid>
                <IconButton onClick={this.handleNext} className="next-btn" disabled={currentBlindDate === blindDates.length-1}>
                    <Icon>forward</Icon>
                </IconButton>
            </Grid>
        )
    };

    handleBack = (event) => {
        if (this.currentBlindDate <= 0) {
            return;
        }

        let visitedDates = this.state.viewedBlindDates;
        if (!visitedDates.includes(this.state.currentBlindDate)) {
            visitedDates = visitedDates.concat(this.state.currentBlindDate);
        }
        this.setState({
            currentBlindDate: this.state.currentBlindDate - 1,
            viewedBlindDates: visitedDates
        });
    };

    handleNext = (event) => {
        if (this.currentBlindDate >= this.props.dates.length-1) {
            return;
        }

        let visitedDates = this.state.viewedBlindDates;
        if (!visitedDates.includes(this.state.currentBlindDate)) {
            visitedDates = visitedDates.concat(this.state.currentBlindDate);
        }
        this.setState({
            currentBlindDate: this.state.currentBlindDate + 1,
            viewedBlindDates: visitedDates
        });
    };

    async handleJudgement() {
        this.setState({awaitUpdate: true});
        const winner = this.props.dates[this.state.currentBlindDate].playerIndex;
        this.props.onJudge(winner);
    }
}
export default JudgeComponent