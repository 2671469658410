import React, {Component} from 'react';
import './NoGame.scss';
import { withRouter } from "react-router-dom";
import service from "./GameService";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Slide } from '@material-ui/core';
import FullScreenButton from './Fullscreen';

class NoGameComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      joinGameId: '',
      games: undefined,
      disableCreateGame: false,
      fullscreen: document.fullscreenElement != null,
    }
    this.handleCreateGame = this.handleCreateGame.bind(this)
    this.handleJoinGameSubmit = this.handleJoinGameSubmit.bind(this)
    this.handleJoinGameText = this.handleJoinGameText.bind(this)
  }

  /*
  async componentDidMount() { 
    const selfPlayer = await service.getSelfPlayer();
    if(selfPlayer.error) {
      console.log(selfPlayer.error);
    }
    else {
      this.setState({ 
        games: selfPlayer.games
      });
    }
  }
  */

  render() {
    const loadFlag = true;
    return (
      <Container className="no-game-container">
        <Grid container direction="column" justify="space-evenly" alignItems="center">
          <Grid container justify="space-evenly" alignItems="center" style={{'width':'auto'}}>
            <Slide direction="right" in={loadFlag} mountOnEnter timeout={2000}>
              <img id="flag-logo" src="/assets/flag-logo.png" alt="logo"></img>
            </Slide>

            <Grid container direction="column" justify="space-evenly" alignItems="center" style={{'width': 'auto'}}>
              <Typography variant="h3" className="game-title">
                Terrible Dates
              </Typography>
              <Typography variant="h4" className="game-subtitle">
                The Game Of Red Flags
              </Typography>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            className="start-game-btn"
            disabled={this.state.disableCreateGame}
            onClick={() => {this.handleCreateGame()}}
          >
            Start New Game
          </Button>

          <form onSubmit={this.handleJoinGameSubmit}>
            <Grid container justify="center" alignItems="flex-end" style={{'margin': '60px 0 46px 0'}}>
              <TextField label="Game Id" value={this.state.joinGameId} onChange={this.handleJoinGameText} className="join-game"/>
              <Button
                variant="contained"
                className="view-game-btn"
                type="submit"
                style={{'marginLeft': '12px'}}
              >
                View Game
              </Button>
            </Grid>
          </form>

        </Grid>
        <FullScreenButton white></FullScreenButton>
      </Container>
    )
  }
  
  async handleCreateGame() {
    await this.setState({disableCreateGame: true})
    const resp = await service.createGame()
    if(resp.uuid !== undefined) {
      this.props.history.push(`/game/${resp.uuid}`);
    }
  }

  handleJoinGameSubmit(event) {
    const gameId = this.state.joinGameId.trim()
    if(gameId !== '') {
      this.props.history.push(`/game/${gameId}`);
    }
  }
  
  handleJoinGameText(event) {
    this.setState({
      joinGameId: event.target.value
    })
  }
}

export default withRouter(NoGameComponent)