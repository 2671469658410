import { Auth } from 'aws-amplify'
const SERVICE_BASE_URL = getServiceUrl()

export default {
    getGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    createGame: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/create`, {
            method: 'POST',
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    joinGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/join`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    startGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/start`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    addBot: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bot`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },
    /**
     * Plays two perks for the round
     * @param {string} gameId 
     * @param {number[]} perks - a two number array
     */
    playPerks: async function(gameId, perks) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/perks`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({perks})
        })
        return await resp.json()
    },
    /**
     * Plays 1 flag for the round
     * @param {string} gameId 
     * @param {number} flag - a single number
     */
    playFlag: async function(gameId, flag) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/flag`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({flag})
        })
        return await resp.json()
    },

    /**
     * Picks a winner by it's player index
     * @param {string} gameId 
     * @param {number} winner - index of player in the players array
     */
    judge: async function(gameId, winner) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/judge`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({winner})
        })
        return await resp.json()
    },

    /**
     * Player signals they are ready to move to the next round
     * @param {string} gameId 
     */
    ready: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/ready`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    /**
     * Update Game settings
     * @param {string} gameId 
     * @param {number} points - number of points for victory criteria
     */
    updateGameSettings: async function(gameId, points) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/points`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({points})
        })
        return await resp.json()
    },

    flags: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/flags`, {
            headers: { 
                ...(await this.authHeader()), 
            },
        })
        return await resp.json()
    },

    perks: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/perks`, {
            headers: { 
                ...(await this.authHeader()), 
            },
        })
        return await resp.json()
    },

    authHeader: async function() {
        const authdata = await Auth.currentSession()
        return {
            'Authorization': `Bearer ${authdata.getIdToken().getJwtToken()}`
        }
    },
}

function getServiceUrl() {
    return "https://kinrrcx64k.execute-api.us-east-1.amazonaws.com/prod"
}