import React, { Component } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    IconButton,
    Icon,
    Slide,
} from '@material-ui/core';
import './WinnerDialog.scss';
import PlayingCard from './Card';


class WinnerDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        const judge = this.props.game.players[this.props.game.lastWinner.judge];
        const winner = this.props.game.players[this.props.game.lastWinner.winner];
        const flag = this.props.flags[this.props.game.lastWinner.flag];
        const perk0 = this.props.perks[this.props.game.lastWinner.perks[0]];
        const perk1 = this.props.perks[this.props.game.lastWinner.perks[1]];

        return (
            <Dialog open={this.state.isOpen}
                    onClose={() => {this.setState({isOpen: false})}}
                    TransitionComponent={this.transition}
                    className="winner-dialog"
                    fullWidth={true}
                    maxWidth="md">

                <DialogTitle>
                    <b>{judge.name}</b> chose <b>{winner.name}</b>'s friend!
                </DialogTitle>

                <Grid container>
                    <Grid container justify="space-evenly">
                        <PlayingCard 
                            key={'perk-0'}
                            card={{description: perk0}} 
                            perk
                            flipDelay={500}
                            disableClick={true} />
                        <PlayingCard 
                            key={'perk-1'}
                            card={{description: perk1}} 
                            perk
                            flipDelay={700}
                            disableClick={true} />
                        <PlayingCard 
                            key={'flag-0'}
                            card={{description: flag}}
                            flipDelay={900}
                            disableClick={true} />
                    </Grid>
                </Grid>

                <IconButton className="close-btn" onClick={() => this.setState({isOpen: false})}>
                    <Icon>close</Icon>
                </IconButton>
            </Dialog>
        )
    };
}
export default WinnerDialogComponent